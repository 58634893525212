import { getCurrentInstance,reactive,toRefs,onBeforeMount,onMounted,defineComponent} from 'vue';
import language from './personInfoLanguage'
import ChangePersonalInfosUtil,{IOraganizationBaseOtherInfos,IBaseInfoForm,IModifyPwdForm,IChangePersonalInfosDataObj} from "@/views/sysviews/user/ts/changePersonalInfo/changePersonalInfosUtil";
let oraganizationBaseOtherInfos:IOraganizationBaseOtherInfos={
    corp:'',
    dept:'',
    position:'',
    birthdate:'',
    sex:''
}
let baseInfoForm:IBaseInfoForm={
    nickName:'',
    mobile:'',
    email:''
}
let modifyPwdForm:IModifyPwdForm={
    prePass: "",
    newPass: "",
    checkPass: ""
}
import userImg from '@/assets/user.jpg';
export default defineComponent({
    name: "ChangePassword",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let validatePass = (rule:any, value:any, callback:any) => {
            if (value === "") {
                callback(new Error(proxy.$t('personInfo.pass_placeholder')));
            } else {
                if (dataObj.modifyPwdForm.checkPass !== "") {
                    dataObj.refMap.get('modifyPwdFormRef').validateField("checkPass");
                }
                callback();
            }
        };
        let validatePass2 = (rule:any, value:any, callback:any) => {
            if (value === "") {
                callback(new Error(proxy.$t('personInfo.repass_placeholder')));
            } else if (value !== dataObj.modifyPwdForm.newPass) {
                callback(new Error(proxy.$t('personInfo.repass_wrong_placeholder')));
            } else {
                callback();
            }
        };
        proxy.addLangProps(language);
        let dataObj:IChangePersonalInfosDataObj=reactive<IChangePersonalInfosDataObj>({
            refMap:new Map(),
            fullscreenLoading:false,
            activeName:'baseInfo',
            avatarImage: userImg,//用户头像图片路径
            canSave:false,
            personalInfos:{},
            oraganizationBaseOtherInfos:oraganizationBaseOtherInfos,
            baseInfoForm:baseInfoForm,
            modifyPwdForm: modifyPwdForm,
            baseInfoFormRules:utils.UtilPub.commonFormValidRule([{nickName:proxy.$t('personInfo.nickName_rule')}]),
            modifyPwdFormRules:{
                prePass: [{required: true,validator: validatePass, trigger: "blur"}],
                newPass: [{required: true,validator: validatePass, trigger: "blur"}],
                checkPass: [{required: true,validator: validatePass2, trigger: "blur"}]
            },
            showLeft:true
        })
        let changePersonalInfosUtilInst: ChangePersonalInfosUtil=new ChangePersonalInfosUtil(proxy,dataObj);
        onMounted(async ()=>{
            let res = await utils.Api.personalInfos({});
            if(res.result){
                const personalInfos=JSON.parse(res.personalInfos);
                dataObj.personalInfos=personalInfos;
                dataObj.canSave=res.canSave;
                dataObj.baseInfoForm.nickName=personalInfos.nickName;
                dataObj.baseInfoForm.mobile=personalInfos.mobile;
                dataObj.baseInfoForm.email=personalInfos.email;

                dataObj.oraganizationBaseOtherInfos.corp=personalInfos.corp;
                dataObj.oraganizationBaseOtherInfos.dept=personalInfos.dept;
                dataObj.oraganizationBaseOtherInfos.position=personalInfos.position;
                dataObj.oraganizationBaseOtherInfos.birthdate=personalInfos.birthdate;
                dataObj.oraganizationBaseOtherInfos.sex=personalInfos.sex;
            }
        })


        return{
            ...toRefs(dataObj),changePersonalInfosUtilInst
        }
    }
});